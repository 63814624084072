<!--
  * @desc:
  * @author: 肖
  * @create_date: 2021/6/5
  * @BSD
-->
<template>
  <div class="girl-open-wrap">
    <top-bar-black>
      <template slot="right">
        <div class="tickets-line">
          <div class="left"></div>
          <div class="right">
            <div class="right-1">
              <img src="../../assets/nsmh_icon_bx_big.png" mode="" />
              <div class="text">{{ mhTicketsNum }}</div>
            </div>
            <div class="right-1 right-2">
              <img src="../../assets/quan.png" mode="" />
              <div class="text">{{ ccTicketsNum }}</div>
            </div>
          </div>
        </div>
      </template>
    </top-bar-black>
    <div class="main">
      <!-- 动画部分 -->
      <div class="gif-wrap" @click="openBox">
        <img class="gif" :src="src" />
        <img
                v-if="openBoxInfo && isOpen"
                class="gif-pd"
                :src="openBoxInfo.small_image_url" />
      </div>

      <!-- 列表部分 -->
      <div class="list-wrap" v-if="!isOpen">
        <template v-for="(item, index) in productList">
          <div class="item" :key="index"
                :style="{
						'animation-play-state': itemAnimationState,
						'animation-delay': getDisplydelay(index)
						}">
            <div class="font" :style="{'animation-delay': getdelay(index)}"></div>
            <div class="back" :style="{'animation-delay': getdelay(index)}">
              <img :src="item.small_image_url" />
            </div>
          </div>
        </template>
      </div>
      <div class="result" v-else>
        <div class="line1">
          <img class="line1-1" src="../../assets/nsmh_text_gxhd.png" mode="" />
          <div class="line1-2">
            价值
            <div class="red1">
              {{openBoxInfo.product_market_price}}
              <div class="bg">{{openBoxInfo.product_market_price}}</div>
            </div>元的
          </div>
          <div class="line1-3">
            <div class="red2">
              {{ openBoxInfo.product_name }}
              <div class="bg">{{ openBoxInfo.product_name }}</div>
            </div>
          </div>
<!--          <div class="order-wrap" @click="gotoOrder">-->
<!--            <img src="../../static/assets/nsmh_btn_form@2x.png" mode=""></img>-->
<!--          </div>-->
        </div>
        <div class="line2">
          <img class="img1" src="../../assets/nsmh_bg_hqcc.png" mode="" @click="resetOpenBox" />
          <img class="img2" src="../../assets/nsmh_bg_ljqf.png" mode="" @click="gotoSign" />
        </div>
        <div class="line3">
          <img src="../../assets/nsmh_bg_jxk.png" mode="" @click="goOnOpen" />
        </div>
      </div>
    </div>

    <buy-mh ref="buyMh" :group_id="openBoxInfo.group_id" @refresh="afterBuyMh"></buy-mh>
    <buy-cc ref="buyCc" :group_id="openBoxInfo.group_id" @refresh="afterBuyCC"></buy-cc>
  </div>
</template>

<script>
  import topBarBlack from '../../components/top-bar-black'
  import buyMh from '../../components/buy-mh'
  import buyCc from '../../components/buy-cc'
  import { getCouponsCount, getWaitOpen, startOpenBox, resetOpenBox, getGroupList } from '../../common/api/shop'
  export default {
    components: {
      topBarBlack,
      buyMh,
      buyCc
    },
    data() {
      return {
        item: {},
        tickets: [],
        waitBox: [],
        waitBoxTotal: 0,
        isOpen: false, // 是否开合完成
        openBoxInfo: {},
        mhTicketsNum: 0, // 盲盒数量
        ccTicketsNum: 0, // 重抽数量
        isReapeat: false, // 是否是重抽

        list: 32,
        src: "http://mhstatic.dinotd.com/static/mhcc.gif",
        itemAnimationState: 'paused',
        canOpen: false,

        productList: [],
        isfromCC: false
      };
    },
    mounted () {
      if (this.$route.params.curProduct) {
        this.openBoxInfo = this.$route.params.curProduct
        this.isfromCC = true
      }

      this.initTicket()
      this.initBox()
      this.initProduct()

      // 加载动画
      setTimeout(() => {
        this.src = "http://mhstatic.dinotd.com/static/jzfd.gif"
        this.canOpen = true
      }, 1000)
    },
    methods: {
      initProduct() {
        getGroupList({
          data: {
            group_id: this.item.id || '1',
            size: 1000
          },
          success: res => {
            if (res.code == 200) {
              this.productList = res.data.list
            }
          }
        })
      },
      refresh () {
        this.initTicket()
        this.initBox()
      },
      afterBuyMh () {
        this.initTicket()
        this.initBox()
        this.isOpen = false
        this.resetPage()
      },
      afterBuyCC () {
        this.initTicket()
        this.initBox()
        this.isOpen = false
        this.isReapeat = true
        this.resetPage()
      },
      // 初始化券
      initTicket () {
        getCouponsCount({
          data: {
            group_id: this.item.id || 1
          },
          success: (res) => {
            if (res.code == 200) {
              this.mhTicketsNum = res.data.lottery_ticket_num
              this.ccTicketsNum = res.data.reset_lottery_ticket_num

              if (this.isfromCC) {
                this.isfromCC = false
                if (this.ccTicketsNum == 0) {
                  this.$refs.buyCc.open()
                } else {
                  this.isOpen = false
                  this.isReapeat = true
                }
              }
            }
          }
        })
      },
      // 获取待开启的盒子
      initBox () {
        getWaitOpen({
          success: res => {
            if (res.code == 200) {
              this.waitBox = res.data.list
              this.waitBoxTotal = res.data.total
            }
          }
        })
      },
      // 打开盒子
      openBox () {
        if (this.mhTicketsNum == 0 && this.ccTicketsNum == 0) return
        if (this.canOpen) {
          this.canOpen = false
          // 动画加载
          this.itemAnimationState = 'running'
          this.src = "http://mhstatic.dinotd.com/static/kxqd.gif"
          setTimeout(() => {
            this.isOpen = true
            this.src = "http://mhstatic.dinotd.com/static/wbxhd.gif"
          }, 1800)
          // 请求接口
          if (!this.isReapeat) { // 非 重抽
            startOpenBox({
              data: {
                group_id: this.item.id || 1
              },
              success: res => {
                if (res.code == 200) {
                  this.openBoxInfo = res.data
                  this.refresh()
                }
              }
            })
          } else {
            resetOpenBox({ // 重抽
              data: {
                group_id: this.openBoxInfo.group_id || 1,
                box_id: this.openBoxInfo.id
              },
              success: res => {
                if (res.code == 200) {
                  this.openBoxInfo = res.data
                  this.isReapeat = false
                  this.refresh()
                }
              }
            })
          }
        }
      },
      // 继续开
      goOnOpen () {
        if (this.mhTicketsNum == 0) {
          this.$refs.buyMh.open()
        } else {
          this.isOpen = false
          this.resetPage()
        }
      },
      // 重抽
      resetOpenBox () {
        if (this.ccTicketsNum == 0) {
          this.$refs.buyCc.open()
        } else {
          this.isOpen = false
          this.isReapeat = true
          this.resetPage()
        }
      },
      // 重置页面
      resetPage () {
        this.itemAnimationState = 'paused'
        this.src = "http://mhstatic.dinotd.com/static/jzfd.gif"
        this.canOpen = true
      },
      // 签发
      gotoSign () {
        // uni.navigateTo({
        //   url: '/pagesSign/sign/sign',
        //   success: res => {
        //     res.eventChannel.emit('acceptDataFromOpenerPage', {
        //       data: [this.openBoxInfo]
        //     })
        //   }
        // })
      },
      gotoOrder () {
        // uni.redirectTo({
        //   url: '/pages/stock/stock'
        // })
      },
      // 计算动画延迟时间
      getdelay (index, type) {
        const line = Math.floor(index / 8)
        const yIndex = index - line * 8
        const delay = 80 * line + yIndex * 80
        return delay+ 'ms'
      },
      // 计算动画延迟时间
      getDisplydelay (index) {
        const line = Math.floor(index / 8)
        const yIndex = index - line * 8
        const delay = (8 - yIndex) * 80 + ( 4 - line) * 80
        return delay+ 'ms'
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import './boxOpen.scss'
</style>

